import type { FC } from 'react'
import { useMemo } from 'react'
import styled from 'styled-components'

import { useGetParagraphQuery } from '@redux/features/homePage/homePage.api'

import Text from '@typography/Text'
import Title from '@typography/Title'
import { abbreviateNumber } from '@utils/number/abbreviateNumber'

type Props = {
  itemId: number
}

const Item: FC<Props> = ({ itemId }) => {
  const { data } = useGetParagraphQuery(itemId)
  const title = useMemo(() => abbreviateNumber(data?.title || ''), [data?.title])

  return (
    <Container>
      <Block>
        <DesktopBlock>
          <Title level={4}>{data?.title}</Title>
        </DesktopBlock>
        <TabletBlock>
          <Title level={5}>{title}</Title>
        </TabletBlock>
        <SubTitle size="s">{data?.sub_title}</SubTitle>
      </Block>
      <BlockIcon>
        {data?.svg_icon && <img alt="icon" height="24" loading="lazy" src={data.svg_icon.url} width="24" />}
      </BlockIcon>
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  ${p => p.theme.mediaQueries.mobileTablet} {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    padding-bottom: 0;
  }
`

const Block = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  align-items: center;
  border-inline-end: 1px solid ${p => p.theme.colors.fontSecondaryLight};
  padding: 10px 0;
  padding-inline-end: 24px;
  white-space: nowrap;
  text-align: end;
  width: 160px;
  max-height: 88px;
  text-transform: uppercase;

  ${p => p.theme.mediaQueries.mobileTablet} {
    border-right: none;
    border-left: none;
    border-top: 1px solid ${p => p.theme.colors.fontSecondaryLight};
    text-align: center;
    width: 60px;
    white-space: initial;
    height: 88px;
    padding: 0;
  }
`

const BlockIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline-start: 24px;
  width: 24px;

  ${p => p.theme.mediaQueries.mobileTablet} {
    margin-inline-start: 0;
    padding-bottom: 10px;
  }
`

const SubTitle = styled(Text)`
  color: ${p => p.theme.colors.fontSecondaryForm};
  text-align: end;

  ${p => p.theme.mediaQueries.mobileTablet} {
    text-align: center;
  }
`

const DesktopBlock = styled.div`
  ${p => p.theme.mediaQueries.mobileTablet} {
    display: none;
  }
`

const TabletBlock = styled.div`
  display: none;

  ${p => p.theme.mediaQueries.mobileTablet} {
    display: block;
  }
`

export default Item
